import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const BusinessOfficeStaffRoute = [
//  {
//   path: `${SUB_DIRCTORY}/business-office-staff/:action/`,
//   component: React.lazy(() => import("./addBusinessOfficeStaff")),
//  },
 {
  path: `${SUB_DIRCTORY}/business-office-staff`,
  component: React.lazy(() => import("./businessOfficeStaffList")),
 },
];

export default BusinessOfficeStaffRoute;