import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const SubscriptionsRoutes = [
  {
      path: `${SUB_DIRCTORY}/subscriptions/detail/:id/:parkingLocationId`,
      component: React.lazy(() => import("./SubscriptionDetails")),
  },
  {
    path: `${SUB_DIRCTORY}/subscriptions`,
    component: React.lazy(() => import("./SubscriptionsList")),
  },
];

export default SubscriptionsRoutes;