import { SUB_DIRCTORY } from "../app_config";

export const navigations = [
  {
    name: "Dashboard",
    path: `${SUB_DIRCTORY}/dashboard`,
    icon: "dashboard",
    roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"]
  },
  {
    name: "Parking Location",
    path: `${SUB_DIRCTORY}/parking-location`,
    icon: "add_location",
    roles: ["ParkingAdmin", "ParkingSupervisor"]
  },
  {
    name: "Staff",
    path: `${SUB_DIRCTORY}/staff`,
    icon: "group",
    roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"]
  },
  {
    name: "Parking Booking",
    path: `${SUB_DIRCTORY}/parking-booking`,
    icon: "local_parking",
    roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"]
  },
  {
    name: "Check-In Check-Out",
    path: `${SUB_DIRCTORY}/checkIn-checkOut`,
    icon: "add_location",
    roles: ["ParkingAdmin", "ParkingSupervisor"]
  },
  {
    name: "Damaged Vehicle",
    path: `${SUB_DIRCTORY}/damaged-vehicle`,
    // isDisabled: true,
    icon: "commute_icon",
    roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"]
  },
  {
    name: "Business Owner",
    path: `${SUB_DIRCTORY}/business-owner`,
    icon: "group_add_icon",
    roles: ["SuperAdmin"]
  },
  {
    name: "Parking Gate Setting",
    path: `${SUB_DIRCTORY}/parkingLocationSetting`,
    icon: "drive_eta",
    roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"]
  },
  {
    name: "Payment Settings",
    path: `${SUB_DIRCTORY}/session/payment-setting`,
    icon: "attach_money_icon",
    roles: ["ParkingAdmin"]
  },

  // {
  //   name: "Camera Settings",
  //   path: `${SUB_DIRCTORY}/camera-setting`,
  //   icon: "camera_alt",
  //   roles: ["ParkingAdmin", "ParkingSupervisor","ParkingManager"]
  // },
  // {
  //   name: "QR Settings",
  //   path: `${SUB_DIRCTORY}/qr-setting`,
  //   icon: "qr_code",
  //   roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"]
  // },
  {
    name: "QR Settings",
    // path: `${SUB_DIRCTORY}/qr-setting`,
    icon: "qr_code",
    roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"],
    children: [
      {
        name: "Hourly QR",
        path: `${SUB_DIRCTORY}/qr-setting`,
        icon: "qr_code",
        roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"],
      },
      {
        name: "Monthly QR",
        path: `${SUB_DIRCTORY}/monthly-qr-setting`,
        icon: "qr_code",
        roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"],
      }
    ]
  },
  {
    name: "Reports",
    // path: `${SUB_DIRCTORY}/Report`,
    icon: "report_icon",
    roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"],
    children: [
      {
        name: "Revenue",
        icon: "attach_money_icon",
        path: `${SUB_DIRCTORY}/Report/index`,
        roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"],
      },
      {
        name: "Duration",
        icon: "access_time_icon",
        path: `${SUB_DIRCTORY}/duration`,
        roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"],
      },
      {
        name: "Occupancy",
        icon: "table_chart_icon",
        path: `${SUB_DIRCTORY}/occupancy`,
        roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"],
      },
      {
        name: "Live Reports",
        icon: "trending_down",
        path: `${SUB_DIRCTORY}/live-reports`,
        roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"],
      },
      {
        name: "Account Reconciliation",
        icon: "account_balance",
        path: `${SUB_DIRCTORY}/account-reconcilation`,
        roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"],
      },
      {
        name: "Pass Extension Pending",
        path: `${SUB_DIRCTORY}/extendedMonthly-report`,
        icon: "pending_actions",
        roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"]
      },
      {
        name: "Booking Revenue",
        path: `${SUB_DIRCTORY}/Booking-Revenue`,
        icon: "attach_money_icon",
        roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"]
      },
      {
        name: "Account Receivable",
        path: `${SUB_DIRCTORY}/account-receivable`,
        icon: "attach_money_icon",
        roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"]
      },
      {
        name: "Cash Details",
        path: `${SUB_DIRCTORY}/Cash-details`,
        icon: "payment",
        roles: ["ParkingAdmin"]
      },
      {
        name: "Subscriptions",
        path: `${SUB_DIRCTORY}/subscriptions`,
        icon: "attach_money_icon",
        roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"]
      },
    ]
  },
  {
    name: "More",
    icon: "description",
    children: [
      {
        name: "Dummy",
        path: "/dummy",
        icon: "dashboard"
      },
      {
        name: "Map",
        icon: "add_location",
        path: "/map"
      },
      {
        name: "Forms",
        icon: "description",
        children: [
          {
            name: "Basic",
            path: "/forms/basic",
            iconText: "B"
          },
          {
            name: "Editor",
            path: "/forms/editor",
            iconText: "E"
          }
        ]
      },
      {
        name: "Drag and Drop",
        icon: "control_camera",
        path: "/others/drag-and-drop"
      },
      {
        name: "Multilevel",
        icon: "trending_up",
        children: [
          {
            name: "Level 1",
            icon: "list",
            children: [
              {
                name: "Item 1",
                path: "/charts/victory-charts",
                iconText: "1"
              },
              {
                name: "Item 2",
                path: "/charts/react-vis",
                iconText: "2"
              },
              {
                name: "Item 3",
                path: "/charts/recharts",
                iconText: "3"
              },
              {
                name: "Item 4",
                path: "/charts/echarts",
                iconText: "4"
              }
            ]
          }
        ]
      },
      {
        name: "Utilities",
        icon: "format_list_bulleted",
        children: [
          {
            name: "Color",
            path: "/utilities/color",
            iconText: "C"
          },
          {
            name: "Spacing",
            path: "/utilities/spacing",
            iconText: "S"
          },
          {
            name: "Typography",
            path: "/utilities/typography",
            iconText: "T"
          },
          {
            name: "Display",
            path: "/utilities/display",
            iconText: "D"
          }
        ]
      },
      {
        name: "Sessions",
        icon: "trending_up",
        children: [
          {
            name: "Sign in",
            iconText: "SI",
            path: `${SUB_DIRCTORY}/session/signin`
          },
          {
            name: "Sign up",
            iconText: "SU",
            path: `${SUB_DIRCTORY}/session/signup`
          },
          {
            name: "Forgot password",
            iconText: "FP",
            path: `${SUB_DIRCTORY}/session/forgot-password`
          },
          {
            name: "Error",
            iconText: "404",
            path: `${SUB_DIRCTORY}/session/404`
          }
        ]
      },
      {
        name: "UI Kits",
        icon: "favorite",
        badge: { value: "50+", color: "secondary" },
        children: [
          {
            name: "Auto Complete",
            path: "/material/autocomplete",
            iconText: "A"
          },
          {
            name: "Buttons",
            path: "/material/buttons",
            iconText: "B"
          },
          {
            name: "Checkbox",
            path: "/material/checkbox",
            iconText: "C"
          },
          {
            name: "Dialog",
            path: "/material/dialog",
            iconText: "D"
          },
          {
            name: "Expansion Panel",
            path: "/material/expansion-panel",
            iconText: "E"
          },
          {
            name: "Form",
            path: "/material/form",
            iconText: "F"
          },
          {
            name: "Icons",
            path: "/material/icons",
            iconText: "I"
          },
          {
            name: "Menu",
            path: "/material/menu",
            iconText: "M"
          },
          {
            name: "Progress",
            path: "/material/progress",
            iconText: "P"
          },
          {
            name: "Radio",
            path: "/material/radio",
            iconText: "R"
          },
          {
            name: "Switch",
            path: "/material/switch",
            iconText: "S"
          },
          {
            name: "Slider",
            path: "/material/slider",
            iconText: "S"
          },
          {
            name: "Snackbar",
            path: "/material/snackbar",
            iconText: "S"
          },
          {
            name: "Table",
            path: "/material/table",
            iconText: "T"
          }
        ]
      }
    ]
  },
  // {
  //   name: "Charge Back Customer",
  //   path: `${SUB_DIRCTORY}/Charge-back`,
  //   icon: "currency_exchange",
  //   roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"]
  // },
  {
    name: "Business Office",
    path: `${SUB_DIRCTORY}/business-office`,
    icon: "currency_exchange",
    roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"]
  },
  {
    name: "Charge Back Customer",
    path: `${SUB_DIRCTORY}/charge-back-customer`,
    icon: "currency_exchange",
    roles: ["BusinessOffice"]
  },
  {
    name: "Report",
    path: `${SUB_DIRCTORY}/charge-back-customer-report`,
    icon: "attach_money_icon",
    roles: ["BusinessOffice"]
  },
  {
    name: "Whitelist Vehicle",
    path: `${SUB_DIRCTORY}/whiteList-vehicle`,
    icon: "playlist_add_check",
    roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"]
  },
  {
    name: "QR Activity Logs",
    path: `${SUB_DIRCTORY}/qr-activity-logs`,
    icon: "qr_code",
    roles: ["ParkingAdmin", "ParkingSupervisor", "ParkingManager"]
  },

];
